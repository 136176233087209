import React from 'react';
import './App.css';
import galleries from './galleries'
import Gallery from './gallery';

const CONTACT_PARAM = 'contact'

function App() {
  const galleryId = window.location.pathname.split('/')[1]
  const searchParams = new URLSearchParams(document.location.search)
  const showContact = searchParams.get(CONTACT_PARAM) === '1'
  const title = <a className='home' href='/'><h1>leonardo lepsch</h1></a>
  if (galleryId) {
    return <>
      {title}
      <Gallery />
    </>
  }

  return (<>
    {title}
    <div className='mainpage'>
      <div className='content'>
        <ul className='menu'>
          { galleries.map(gallery =>
            <li key={gallery.id}>
              <a href={`/${gallery.id}`}>{ gallery.title }</a>
            </li>) }
          <li className='contact-menu'>
            <a href={`/?${CONTACT_PARAM}=1`}>Contact</a>
          </li>
        </ul>
        <div className={`cover ${showContact ? 'contact' : 'main'}`}>
          { showContact &&
            <div className='contact'>
              <a href='mailto:lepschleonardo@gmail.com'>lepschleonardo@gmail.com</a>
            </div> }
        </div>
      </div>
      <div className='designer'>
        <a href='mailto:stratozell@alice.it'>designed by Pavan Andrea</a>
      </div>
    </div>
    </>
  )
}

export default App;
