import homeMadeWitches from './home-made-witches'
import burano from './burano'
import pipa from './pipa'
import portugalPequeno from './portugal-pequeno'
import rioDasPedras from './rio-das-pedras'
import millsFlour from './mills-flour'

export default [
  homeMadeWitches,
  burano,
  pipa,
  portugalPequeno,
  rioDasPedras,
  millsFlour,
]
